import { Foundation } from 'foundation-sites/js/foundation.core';
import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import { Reveal } from 'foundation-sites/js/foundation.reveal';

Foundation.plugin(Dropdown, 'Dropdown')
Foundation.plugin(Reveal, 'Reveal')

Foundation.addToJquery(jQuery)

$(document).foundation()
